// app/routes/login.tsx
import { json, redirect } from '@remix-run/node';
import {
  useActionData,
  useLoaderData,
  useNavigation,
  useSubmit,
} from '@remix-run/react';
import type {
  MetaFunction,
  ActionFunction,
  LoaderFunction,
} from '@remix-run/node';

import { GaEvent } from '~/utils';
import type { IActionData } from '~/types';
import { commitSession, getSession } from '~/session';
import { Button, GradientContainer, Icons, Label } from 'flow-ui-library';
import { getAuthUser, isUserApproved, signInWithGoogle } from '~/firebase';
import { AgentType } from '~/api';
import { getAgent } from '~/utils/agent-router';

export const loader: LoaderFunction = async ({ request }) => {
  const url = new URL(request.url);
  const searchParams = new URLSearchParams(url.search);
  const referrer = searchParams.get('referrer');

  console.log('url:', url, '  searchParams:', searchParams, '  referrer:', referrer);

  const user = await getAuthUser(request);
  if (user.isSigned) {
    console.log('🔑 user is signed in');
    const redirectTo = referrer && referrer !== '/' ? referrer : '/chat/new';
    return redirect(redirectTo);
  }
  return await getAgent({ request });
};

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  console.log('🎯 meta function');
  console.log('  data:', data);
  const metaData = [
    {
      title: 'Login',
    },
    {
      name: 'description',
      content: `Welcome to ${(data && data?.agent && data?.agent?.name) || 'Flow AI Agent'}!`,
    },
  ];
  console.log('  returning meta:', metaData);
  return metaData;
};

export const action: ActionFunction = async ({ request }) => {
  console.log('🎬 login action');
  try {
    console.log('  getting session');
    const session = await getSession(request.headers.get('Cookie'));

    const clonedData = request.clone();
    const formData = await clonedData.formData();
    
    const idToken = await formData.get('idToken');
    const uid = (formData.get('uid') as string) || '';
    console.log('  uid:', uid);

    console.log('  checking if user is approved');
    const isApproved = await isUserApproved(uid);
    console.log('  isApproved:', isApproved);
    
    if (!isApproved) {
      console.log('  user not approved, returning error');
      return json({
        errorAction: { message: 'Access Denied: User Not Approved' },
      });
    }

    console.log('  setting session data');
    session.set('idToken', idToken);
    session.set('redirectFrom', request.url);

    console.log('  redirecting to chat/new');
    return redirect('/chat/new', {
      headers: { 'Set-Cookie': await commitSession(session) },
    });
  } catch (error) {
    console.log('  error in action:', error);
    let errorMessage = 'Unknown error';
    if (error instanceof Error) errorMessage = error.message;
    return { errorAction: { message: errorMessage } };
  }
};

/**
 * The main component for the login page.
 * @returns The login page component.
 */
export default function Login() {
  console.log('🏁 Login component');
  const { agent }: { agent?: AgentType } = useLoaderData();
  console.log('  agent:', agent);
  const submit = useSubmit();
  const navigation = useNavigation();
  const dataAction = useActionData<IActionData>();
  console.log('  dataAction:', dataAction);

  const onLogin = async () => {
    console.log('  onLogin called');
    try {
      console.log('  signing in with Google');
      const user = await signInWithGoogle();
      console.log('  user:', user);
      const idToken = await user?.getIdToken();
      console.log('  idToken received');
      
      const formData = new FormData();
      formData.set('idToken', idToken);
      formData.set('uid', user?.uid);
      console.log('  submitting form data');
      submit(formData, { method: 'post' });
      
      GaEvent({
        action: 'login-form',
        category: 'login',
        label: user.email || user.uid,
      });
      console.log('  GA event sent');
    } catch (error) {
      console.error('  error in onLogin:', error);
    }
  };

  console.log('  rendering login component');
  return (
    <GradientContainer>
      {agent && agent?.imageLoginUrl ? (
        <img src={agent?.imageLoginUrl} height={80} alt={agent?.name} />
      ) : (
        <div data-type="logo">
          <Icons.LogoPic />
        </div>
      )}
      <div data-type="login-welcome">Welcome back</div>
      <Button
        data-type="login-button"
        variant="primary"
        type="button"
        onClick={onLogin}
      >
        <Icons.Google height={20} />
        {navigation.state === 'loading'
          ? 'Checking user...'
          : navigation.state === 'submitting'
            ? 'Authenticating...'
            : 'Sign in With Google'}
      </Button>
      {navigation.state === 'idle' && dataAction?.errorAction && (
        <div data-type="label-welcome">
          <Label>{dataAction?.errorAction?.message}</Label>
        </div>
      )}
    </GradientContainer>
  );
}
